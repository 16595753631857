import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Seo } from 'components/contentful/seo/Seo';
import { Hero } from 'components/contentful/hero/Hero';
import { MediaTextContentful } from 'components/contentful/media-text/MediaText';
import { InfoListContentful } from 'components/contentful/info-list/InfoList';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';
import { WrapperBgs } from 'components/container/WrapperBgs'


import { UIContext } from 'context/ui';
import { BillboardTextContentful } from 'components/contentful/billboard-text/BillboardText';
import gsap from 'gsap';
import DrawSVGPlugin from '../utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitText from '../utils/SplitText/SplitText';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { VideoItem } from 'components/video-item/VideoItem';
import { QuoteCarousel } from 'components/quote-carousel/QuoteCarousel';
import { Line1 } from 'components/bg-svgs/lines/culture/line1/Line1';
import { Line2 } from 'components/bg-svgs/lines/culture/line2/Line2';
import { Line3 } from 'components/bg-svgs/lines/culture/line3/Line3';
import { Line4 } from 'components/bg-svgs/lines/culture/line4/Line4';


gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSRulePlugin);


export default ({ data }: any) => {

  const { isMobile, isDesktop } = useContext(UIContext);
  const {contentfulData} = data;
  const seo = contentfulData.seo;

  return (
    <>
      <BgGradient />
      {
        seo &&
        <Seo seo={seo}/>
      }
      <WrapperBgs overflowMobile={true}>
        <Hero clearPaddingBottom={true} hero={{...contentfulData.hero, clearPaddingBottom: true}}/>
        <VideoItem autoplay={true} item={contentfulData.body[0]}/>
        {
          isDesktop && <Line1/>
        }
      </WrapperBgs>

      <WrapperBgs>
        <BillboardTextContentful
          style={{
            marginTop: isDesktop && 100
          }}
          billboardText={contentfulData.body[1]}
        />
        <QuoteCarousel
          list={contentfulData.body[2]}
          style={{
            marginBottom: isDesktop ? 190 : 60,
            marginTop: isDesktop ? 80 : 0
          }}
        />
        {
          isDesktop && <Line2/>
        }
        {
          isDesktop && <Line3/>
        }
      </WrapperBgs>

      <WrapperBgs>
      <InfoListContentful
          titlePlacement="inside"
          borderRadius="topRight"
          titleAlignTop={false}
          lessPaddingTop={false}
          lessPaddingBottom={true}
          evenColumns={true}
          infoList={contentfulData.body[3]}
          style={{
            marginBottom: isMobile && 100,
            paddingTop: isMobile && 100
          }}
        />
        <InfoListContentful
          titlePlacement="inside"
          titleAlignTop={false}
          borderRadius="bottomLeft"
          imageAlignment="left"
          contentExtraMarginTop={true}
          lessPaddingTop={true}
          lessPaddingBottom={true}
          evenColumns={true}
          infoList={contentfulData.body[4]}
          style={{
            marginBottom: isDesktop ? 190 : 60
          }}
        />
        {
          isDesktop && <Line4/>
        }
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful
          style={{marginBottom: isDesktop && 160,
                  paddingBottom: isDesktop && 0,
                }}
          titlePlacement="inside"
          borderRadius="topRight"
          infoList={contentfulData.body[5]}
          imageAlignment="right"
          expandedOnMobile={true}
        />
      </WrapperBgs>

      <WrapperBgs>
        <BillboardTextContentful billboardText={contentfulData.body[6]}/>
        {
          isDesktop && <Line2/>
        }
      </WrapperBgs>

      <WrapperBgs>
        <VideoItem
            justifyLeft={true}
            autoplay={true}
            item={{...contentfulData.body[7]}}
            style={{
                borderRadius: isDesktop ? '70px 70px 0 0' : '40px 40px 0 0',
                marginBottom: isDesktop ? 200 : 60
            }}
        />
      </WrapperBgs>

      <WrapperBgs>
        <QuoteCarousel
          list={contentfulData.body[8]}
          style={{
            marginBottom: isDesktop ? 190 : 60,
            marginTop: isMobile && 100
          }}
        />
        <MediaTextContentful 
          mediaText={contentfulData.body[9]}
          layout="column"
        />
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
{
  contentfulData: contentfulPage(name: {eq: "Culture"}) {
    seo {
      description
      title
      twitterImage {
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
        description
      }
      facebookImage {
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
        description
      }
      no_index
      no_follow
      name
    }
    name
    body {
      ... on ContentfulBillboard {
        id
        title
        text {
          raw
        }
      }
      ... on ContentfulInfoList {
        id
        title
        cta {
          href
          label
        }
        description {
          raw
        }
        items {
          image {
            description
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
          title
          text {
            raw
          }
          video {
            file {
              url
            }
            description
          }
        }
        subtitle
      }
      ... on ContentfulList {
        id
        title
        text {
          raw
        }
        cta {
          label
          href
        }
        items {
          title
          text {
            raw
          }
          autoplay
          showPlayButton
          videoUrl
          quote {
            author
            text {
              raw
            }
            cta {
              label
              href
            }
            avatar {
              description
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
            }
          }
          image {
            gatsbyImageData
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            description
          }
          video {
            description
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          description
        }
      }
      ... on ContentfulQuote {
        id
        author
        text {
          raw
        }
      }
      ... on ContentfulMediaText {
        id
        videoUrl
        autoplay
        showPlayButton
        sectionBackground {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        video {
          description
          file {
            details {
              image {
                width
                height
              }
            }
            url
          }
        }
        title
        text {
          raw
        }
        image {
          gatsbyImageData
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        cta {
          href
          label
        }
        imageMobile {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      ... on ContentfulVideo {
        id
        videoUrl
        video {
          description
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
    hero {
      title
      text {
        raw
      }
    }
  }
}
`